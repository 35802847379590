<template>
    <v-container fluid>
        <PurchaseOrderSearch v-if="showPurchaseOrderSearch" :orderNo.sync="purchaseOrderNumber" :vendors="vendors" :productName.sync="purchaseItemShow"
                                     v-on:updatePurchase="updatePurchase" v-on:chooseProduct="toStep1"></PurchaseOrderSearch>


        <v-stepper class="mb-5" v-model="step">
            <v-stepper-header>
                <v-stepper-step @click="step = 1" step="1">{{$vuetify.lang.t('$vuetify.receiving.purchaseOrder')}}</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step @click="step = 2" step="2">{{$vuetify.lang.t('$vuetify.receiving.entranceWeight')}}</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step @click="step = 3" step="3">{{$vuetify.lang.t('$vuetify.receiving.dropOff')}}</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step @click="step = 4" step="4">{{$vuetify.lang.t('$vuetify.receiving.exitWeight')}}</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step @click="step = 5" step="5">{{$vuetify.lang.t('$vuetify.receiving.reviewAndPrint')}}</v-stepper-step>
            </v-stepper-header>
        </v-stepper>

        <v-card v-if="step == 1">
            <v-card-text>
                <PurchaseOrderItem :purchaseItem.sync="purchaseItemShow" :purchaseItems.sync="purchaseItemsShow"
                                   v-on:updatePurchaseItem="updatePurchaseItem"
                                   v-on:purchaseItemChange="purchaseItemChange"></PurchaseOrderItem>
            </v-card-text>
            <v-card-actions class="mr-5">
                <v-spacer/>
                <v-btn primary @click="itemToNext">{{$vuetify.lang.t('$vuetify.receiving.next')}}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>

        <v-card v-if="step == 2">
            <v-card-text>
                <v-form v-model="valid" lazy-validation>
                    <v-text-field
                            :label="$vuetify.lang.t('$vuetify.receiving.truncNo')"
                            v-model="purchaseItem.truckNo"
                            counter="20"
                            append-icon="mdi-camera"
                    />
                    <v-text-field
                            :label="$vuetify.lang.t('$vuetify.receiving.grossWeight')"
                            hint="The total weight of the truck with delivering products"
                            v-model="purchaseItem.fullWeight"
                            :rules="[() => !!purchaseItem.fullWeight || 'This field is required', !isNaN(purchaseItem.fullWeight) || 'this filed need number']"
                            append-icon="mdi-camera"
                    />
                    <v-select
                            v-model="purchaseItem.fullWeightLocationId"
                            :items="weightStations"
                            :label="$vuetify.lang.t('$vuetify.receiving.grossWeightStation')"
                    ></v-select>
                </v-form>
            </v-card-text>
            <v-card-actions class="mr-5">
                <v-spacer/>
                <v-btn primary @click="next(3)">{{$vuetify.lang.t('$vuetify.receiving.next')}}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>

        <v-card v-if="step == 3">
            <v-card-text>
                <v-form v-model="valid" lazy-validation>
                    <v-select
                            v-model="purchaseItem.dropWeightLocationId"
                            :items="locations"
                            :label="$vuetify.lang.t('$vuetify.receiving.dropOffLocation')"
                    ></v-select>
                </v-form>
            </v-card-text>
            <v-card-actions class="mr-5">
                <v-spacer/>
                <v-btn primary @click="next(4)">{{$vuetify.lang.t('$vuetify.receiving.next')}}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>

        <v-card v-if="step == 4">
            <v-card-text>
                <v-form v-model="valid" lazy-validation>
                    <v-select
                            v-model="purchaseItem.tareWeightLocationId"
                            :items="weightStations"
                            :label="$vuetify.lang.t('$vuetify.receiving.tareWeightStation')"
                    ></v-select>
                    <v-text-field
                            :label="$vuetify.lang.t('$vuetify.receiving.tareWeight')"
                            v-model="purchaseItem.tareWeight"
                            append-icon="mdi-camera"
                    />
                </v-form>
            </v-card-text>
            <v-card-actions class="mr-5">
                <v-spacer/>
                <v-btn primary @click="next(5)">{{$vuetify.lang.t('$vuetify.receiving.next')}}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>

        <v-card v-if="step == 5">
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="12" md="6" lg="3">
                        <v-card class="pa-2">
                            <strong>{{$vuetify.lang.t('$vuetify.receiving.productName')}}:</strong> {{purchaseItem.productName}}
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="3">
                        <v-card class="pa-2">
                            <strong>{{$vuetify.lang.t('$vuetify.receiving.truncNo')}}:</strong> {{purchaseItem.truckNo}}
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="3">
                        <v-card class="pa-2">
                            <strong>{{$vuetify.lang.t('$vuetify.receiving.grossWeight')}}:</strong> {{purchaseItem.fullWeight}}
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="3">
                        <v-card class="pa-2">
                            <strong>{{$vuetify.lang.t('$vuetify.receiving.grossWeightStation')}}:</strong> {{purchaseItem.fullWeightLocationName}}
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="3">
                        <v-card class="pa-2">
                            <strong>{{$vuetify.lang.t('$vuetify.receiving.dropOffLocation')}}:</strong> {{purchaseItem.dropWeightLocationName}}
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="3">
                        <v-card class="pa-2">
                            <strong>{{$vuetify.lang.t('$vuetify.receiving.tareWeight')}}:</strong> {{purchaseItem.tareWeight}}
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="3">
                        <v-card class="pa-2">
                            <strong>{{$vuetify.lang.t('$vuetify.receiving.tareWeightStation')}}:</strong> {{purchaseItem.tareWeightLocationName}}
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

    import PurchaseOrderSearch from "@/components/purchaseorder/PurchaseOrderSearch.vue";
    import PurchaseOrderItem from "@/components/purchaseorder/PurchaseOrderItem.vue";
    import {mapActions, mapGetters} from "vuex";

    export default {
        data() {
            return {
                showPurchaseOrderSearch: false,
                loading: true,
                step: 1,
                valid: false,
                purchase: null,

                locations: [],
                WeightStations:[],

                purchaseOrderNumber: "",
                purchaseOrderId: null,
                vendors: [],
                purchaseItemsShow: [],
                purchaseItemShow: "",
                purchaseItems: [],
                purchaseItem: {},

            };
        },
        computed: {
            ...mapGetters("navigation", ["navState", "isAppLoading"])
        },
        watch: {},
        components: {PurchaseOrderSearch, PurchaseOrderItem},
        created() {
            let step = this.$route.params.step;
            this.init(step);
        },
        methods: {
            ...mapActions("messages", ["addErrorMessage"]),
            init(step) {
                if (step == null) {
                    this.step = 1;
                    this.showPurchaseOrderSearch = true;
                } else {
                    this.step = step;
                    this.$axios.get(
                        "purchase/deduce",
                    ).then((response) => {
                        let data = response.data;
                        if (data.code !== 200) {
                            let message = data.message
                            this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                        } else if (data.data.purchaseVO === null) {
                            this.step = 1;
                            this.showPurchaseOrderSearch = true;
                        } else {
                            console.info("deduce");
                            this.purchase = data.data.purchaseVO;
                            this.purchaseOrderNumber = data.data.purchaseVO.purchaseOrderNumber;
                            this.purchaseOrderId = data.data.purchaseVO.id;
                            this.showPurchaseOrderSearch = true;

                            this.purchaseItems = data.data.purchaseVO.purchaseItemVOList;
                            this.purchaseItem = data.data.currentItem;

                            let purchaseItemsShow = [];
                            for (let i = 0; i < this.purchaseItems.length; i++) {
                                let item = this.purchaseItems[i].productName ;
                                if (this.purchaseItems[i].id != null) {
                                    item = item + "(" + this.purchaseItems[i].id + ")"
                                } else {
                                    item = item + "(+ " + this.$vuetify.lang.t('$vuetify.base.new') + " +)";

                                }
                                purchaseItemsShow.push(item);
                                if (this.purchaseItem.id === this.purchaseItems[i].id) {
                                    this.purchaseItemShow = item;
                                }
                            }
                            this.purchaseItemsShow = purchaseItemsShow;
                        }
                    });
                }
                this.$axios.get(
                    "company", {
                        params: {
                            page: 0,
                            size: 3000,
                            type: "SUPPLIER"
                        }
                    }
                ).then((response) => {
                    let data = response.data;
                    if (data.code !== 200) {
                        let message = data.message
                        this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                        return;
                    } else {
                        let companies = data.data;
                        let items = [];
                        let j = 0
                        for (; j < companies.length; j++) {
                            let company = companies[j];
                            items.push({
                                "text": company.name,
                                "value": company.id
                            });
                        }
                        this.vendors = items;
                    }
                }).catch((error) => {
                    console.error(error)
                });

                this.$axios.get(
                    "category/childs",
                    {
                        params: {
                            code: "G_PURCHASED_PRODUCT"
                        }
                    }
                ).then((response) => {
                    let data = response.data;
                    if (data.code !== 200) {
                        let message = data.message
                        this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                        return;
                    } else {
                        this.products = data.data;
                    }
                });
                this.$axios.get(
                    "category/childs",
                    {
                        params: {
                            code: "I_LOCATION"
                        }
                    }
                ).then((response) => {
                    let data = response.data;
                    if (data.code !== 200) {
                        let message = data.message
                        this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                    } else {
                        let categories = data.data;
                        let items = [];
                        let j = 0
                        for (; j < categories.length; j++) {
                            let category = categories[j];
                            items.push({
                                "text": category.name,
                                "value": category.id
                            });
                        }
                        this.locations = items;
                    }
                });

                this.$axios.get(
                    "category/childs",
                    {
                        params: {
                            code: "WEIGHT_STATION"
                        }
                    }
                ).then((response) => {
                    let data = response.data;
                    if (data.code !== 200) {
                        let message = data.message
                        this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                        return;
                    } else {
                        let categories = data.data;
                        let items = [];
                        let j = 0
                        for (; j < categories.length; j++) {
                            let category = categories[j];
                            items.push({
                                "text": category.name,
                                "value": category.id
                            });
                        }
                        this.weightStations = items;
                    }
                });
            },
            updatePurchase(data) {
                this.purchaseOrderNumber = data.purchaseOrderNumber;
                this.purchaseOrderId = data.id;

                this.purchaseItems = data.purchaseItemVOList;
                let purchaseItemsShow = [];
                for (let i = 0; i < this.purchaseItems.length; i++) {

                    let item = this.purchaseItems[i].productName ;
                    if (this.purchaseItems[i].id != null) {
                        item = item + "(" + this.purchaseItems[i].id + ")"
                    } else {
                        item = item + "(" + this.$vuetify.lang.t('$vuetify.base.new') + ")";

                    }
                    purchaseItemsShow.push(item);
                }
                this.purchaseItemsShow = purchaseItemsShow;
                if (purchaseItemsShow !== null && purchaseItemsShow.length > 0) {
                    this.purchaseItemShow = purchaseItemsShow[0];
                    this.purchaseItem = this.purchaseItems[0];
                } else {
                    this.purchaseItem = null;
                    this.purchaseItemShow = null;
                }
                this.step = 1;
            },
            updatePurchaseItem(data) {
                data.purchaseId = this.purchaseOrderId;
                this.purchaseItems.push(data);
                this.purchaseItem = data;

                let purchaseItemShow = data.productName + "(" + this.$vuetify.lang.t('$vuetify.base.new') + ")";
                this.purchaseItemsShow.push(data.productName + "(" + this.$vuetify.lang.t('$vuetify.base.new') + ")");
                this.purchaseItemShow = purchaseItemShow;
            },
            purchaseItemChange(data) {
                this.purchaseItemShow = data;
                let index = 0;
                for (let i = 0; i < this.purchaseItemsShow.length; i++) {
                    if (this.purchaseItemsShow[i] === data) {
                        index = i;
                    }
                }
                this.purchaseItem = this.purchaseItems[index];
                console.info(JSON.stringify(this.purchaseItem));
            },
            itemToNext() {
                if (this.purchaseOrderId == null) {
                    let message = this.$vuetify.lang.t('$vuetify.note.orderNeed');
                    this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                    return;
                }
                if (this.purchaseItem == null || this.purchaseItem.purchaseId == null) {
                    let message = this.$vuetify.lang.t('$vuetify.note.orderItemNeed');
                    this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                    return;
                }
                let item = this.purchaseItem;
                if (null == item.step || "INIT" === item.step) {
                    this.step = 2;
                    return;
                }
                if (item.step === "FULL_WEIGHED") {
                    this.step = 3;
                    return;
                }
                if (item.step === "DROP_OFFED") {
                    this.step = 4;
                    return;
                }
                if (item.step === "TARE_WEIGHTED") {
                    this.step = 5;
                    return;
                }
            },
            next(step) {
                if (this.purchaseOrderId == null) {
                    let message = this.$vuetify.lang.t('$vuetify.note.orderNeed');
                    this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                    return;
                }
                if (this.purchaseItem == null || this.purchaseItem.purchaseId == null) {
                    let message = this.$vuetify.lang.t('$vuetify.note.orderItemNeed');
                    this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                    return;
                }

                let item = this.purchaseItem;
                if (step === 3) {
                    if (item.truckNo === null || item.truckNo === "" || item.fullWeightLocationId == null || item.fullWeight == null || item.fullWeight == "" || isNaN(item.fullWeight)) {
                        let message = this.$vuetify.lang.t('$vuetify.note.step2UnFinish');
                        this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                        return;
                    }
                    if (item.step === "INIT") {
                        item.step = "FULL_WEIGHED";

                    }
                } else if (step === 4) {
                    if (item.truckNo == null || item.truckNo === "" || item.fullWeightLocationId == null || item.fullWeight == null || item.fullWeight == "" || isNaN(item.fullWeight)) {
                        let message = this.$vuetify.lang.t('$vuetify.note.step2UnFinish');
                        this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                        return;
                    }
                    if (item.dropWeightLocationId == null) {
                        let message = this.$vuetify.lang.t('$vuetify.note.step3UnFinish');
                        this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                        return;
                    }
                    if (item.step === "FULL_WEIGHED") {
                        item.step = "DROP_OFFED"
                    }
                } else if (step === 5) {
                    if (item.truckNo == null || item.truckNo === "" || item.fullWeightLocationId == null || item.fullWeight == null || item.fullWeight == "" || isNaN(item.fullWeight)) {
                        let message = this.$vuetify.lang.t('$vuetify.note.step2UnFinish');
                        this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                        return;
                    }
                    if (item.dropWeightLocationId == null) {
                        let message = this.$vuetify.lang.t('$vuetify.note.step3UnFinish');
                        this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                        return;
                    }
                    if (item.tareWeightLocationId == null || item.tareWeight == null || item.tareWeight == "" || isNaN(item.tareWeight)) {
                        let message = this.$vuetify.lang.t('$vuetify.note.step4UnFinish');
                        this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                        return;
                    }
                    if (parseFloat(item.tareWeight) >= parseFloat(item.fullWeight)) {
                        let message = this.$vuetify.lang.t('$vuetify.note.weightLimit1');
                        this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                        return;
                    }
                    if (item.step === "DROP_OFFED") {
                        item.step = "TARE_WEIGHTED";
                    }

                    for (let i = 0; i <this.weightStations.length; i++) {
                        let station = this.weightStations[i];
                        if(station.value === this.purchaseItem.fullWeightLocationId){
                            this.purchaseItem.fullWeightLocationName = station.text;
                        }
                        if(station.value === this.purchaseItem.tareWeightLocationId){
                            this.purchaseItem.tareWeightLocationName = station.text;
                        }
                    }
                    for (let i = 0; i <this.locations.length; i++) {
                        let location = this.locations[i];
                        if(location.value === this.purchaseItem.dropWeightLocationId){
                            this.purchaseItem.dropWeightLocationName = location.text;
                        }

                    }

                }

                this.$axios.put(
                    "purchase/item",
                    item
                ).then((response) => {
                    var message = response.data.message;
                    if (response.data.code !== 200) {
                        this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'});
                        return;
                    } else {
                        this.step = step;
                        this.purchaseItem.id = response.data.data;
                    }
                }).catch((error) => {
                        console.error(error)
                    }
                );
            },
            toStep1(){
                this.step = 1;
            }
        }
    };
</script>

<style>
</style>